/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    br: "br",
    h3: "h3"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, LinkWithQuery, SideBySide, StateCta, LandingPageCta, QuestionAccordion, ThreeColCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!LinkWithQuery) _missingMdxReference("LinkWithQuery", true);
  if (!QuestionAccordion) _missingMdxReference("QuestionAccordion", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!StateCta) _missingMdxReference("StateCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/technology/bluetooth/'], ['en', 'https://www.hear.com/hearing-aids/technology/bluetooth/'], ['en-US', 'https://www.hear.com/hearing-aids/technology/bluetooth/'], ['en-CA', 'https://ca.hear.com/hearing-aids/technology/bluetooth/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "improve-your-quality-of-life",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#improve-your-quality-of-life",
    "aria-label": "improve your quality of life permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Improve Your Quality of Life"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Bluetooth hearing aids wirelessly connect to your smartphone, TV, computer, and more – allowing you to stream HD quality audio directly to your ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), ". The future is finally “hear!”"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(LinkWithQuery, {
    to: "/hearing-aids/technology/"
  }, "Modern hearing aids technology"), " improve your quality of life by delivering brilliant sound quality during conversations. Bluetooth hearing aids, on the other hand, do that and deliver incredible sound quality streamed wirelessly from your electronic devices. In the past, ", React.createElement(LinkWithQuery, {
    to: "/hearing-aids/"
  }, "hearing aids"), " had trouble picking up sound from phone receivers. These sounds were over amplified and not fine-tuned, causing hearing aids to whistle."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "are-bluetooth-hearing-aids-better",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#are-bluetooth-hearing-aids-better",
    "aria-label": "are bluetooth hearing aids better permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Are Bluetooth Hearing Aids Better?"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/bluetooh-lifestyle.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Bluetooth connectivity does not make your hearing aids perform better, but rather, it allows you to connect your hearing aids with any Bluetooth-enabled electronic device and make their use easier for you.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "Here are the main advantages:")), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "watching-tv",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#watching-tv",
    "aria-label": "watching tv permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Watching TV:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Those with hearing loss often understand the TV better with headphones. By streaming the TV audio directly to your hearing aids, you are not only eliminating any reverberation that may occur between the TV speakers and your ears, but you’re eliminating the need for headphones! You can also adjust the hearing aid volume from an easy-to-use App instead of on the TV."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "phone-conversations",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#phone-conversations",
    "aria-label": "phone conversations permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Phone conversations:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Talking on the phone while wearing hearing aids can be difficult. And it isn’t always comfortable either, as you need to hold the speaker near the your device’s microphone. With some hearing aids, you might also hear an annoying whistling sound. Bluetooth hearing aids making talking on the phone easy and stress-free!"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "listening-to-music",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#listening-to-music",
    "aria-label": "listening to music permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Listening to music:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Enjoy your favorite music streamed directly to your bluetooth hearing aids, without wearing uncomfortable headphones!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Additionally, streaming audio from your electronic devices to your hearing aids will filter out background noise and allow you to fully enjoy the sounds you really want to hear, like your grandchild talking on the phone, without any whistling. You can also take a walk in the park while talking on the phone, not worrying about extraneous environmental sounds, or even talk on the phone at your local coffee shop without being bothered by background chatter. The possibilities are endless with Bluetooth-enabled hearing aids."), "\n", React.createElement(StateCta, {
    backgroundColor: "white"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-much-is-a-bluetooth-hearing-aid",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-much-is-a-bluetooth-hearing-aid",
    "aria-label": "how much is a bluetooth hearing aid permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How Much Is a Bluetooth Hearing Aid?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Bluetooth connectivity does not make the device more expensive. However, this feature is not yet available on Basic hearing aids. For you to enjoy the benefits of a Bluetooth hearing aid, you’ll need to purchase a device that’s at the Mid-Range or Premium technology level."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-get-used-to-your-bluetooth-hearing-aid",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-get-used-to-your-bluetooth-hearing-aid",
    "aria-label": "how to get used to your bluetooth hearing aid permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to Get Used to Your Bluetooth Hearing Aid?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "All hearing aids are programmed to meet your specific auditory needs. ", React.createElement(_components.a, {
    href: "/audiologists/",
    className: "c-md-a"
  }, "An audiologist"), " will set your hearing aid settings by calibrating the frequencies, volume, and gain levels necessary for answering phone calls through your hearing aids via Bluetooth. This ensures a high-quality audio experience."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Once you take home your new devices, you can continue to exercise maximum control over its settings through a smartphone App or streamer. With Bluetooth hearing aids that are compatible with iPhones or Android smartphones, you control the volume and other settings ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/apps/",
    className: "c-md-a"
  }, "from the manufacturers’ Apps"), ". If your hearing aid has a wireless connection to a streamer, you can adjust its settings using the streamer."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-is-a-bluetooth-hearing-aid",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-a-bluetooth-hearing-aid",
    "aria-label": "what is a bluetooth hearing aid permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What Is a Bluetooth Hearing Aid?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "T-Coils, also known as telecoils, are located inside hearing aids and receive FM signals (or electromagnetic signals) from a Bluetooth-enabled device (e.g. your smartphone or TV) via a streamer. When the T-coil receives a signal, the microphone in the hearing aid turns off. This reduces background interference and stops the whistling sound experienced during phone calls. Without extraneous sounds coming into your hearing aid, you can focus on the sounds coming from your phone, television or music player."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Now, smartphones and televisions wirelessly connect to Bluetooth hearing aids either directly or through a small streamer device to deliver unparalleled sound quality. Some hearing aid manufacturers offer Bluetooth hearing aids that are compatible with Android smartphones and iPhones, creating a seamless connection to your electronic device. All manufacturers offer streamer accessories which convert a Bluetooth signal from an electronic device into FM signals. Then, the streamer sends the FM signal to your hearing aids without signal disruptions. Both the streamer and direct connection options offer optimal clarity and ease of use."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "find-your-bluetooth-hearing-aid-with-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#find-your-bluetooth-hearing-aid-with-hearcom",
    "aria-label": "find your bluetooth hearing aid with hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Find Your Bluetooth Hearing Aid With hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We live in an increasingly connected world, and connecting your hearing aids to your electronic devices is now more important than ever. Bluetooth-enabled hearing aids make your life much easier. In the comfort of your home, you can easily switch from talking on your phone to watching television, as well as seamlessly listen to music directly from your hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com we are ready to answers all your questions about the latest Bluetooth-enabled hearing aids. From streaming accessories to Bluetooth hearing aids for iPhone or Android, we will go into greater detail about all the latest features of these incredible devices. We look forward to getting in touch with you soon."), "\n", React.createElement(LandingPageCta, {
    copy: "Start no-risk trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "selection-of-bluetooth-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#selection-of-bluetooth-hearing-aids",
    "aria-label": "selection of bluetooth hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Selection of Bluetooth Hearing Aids"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ix-hearing-aids-box-black.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "horizon-go-ix-by-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#horizon-go-ix-by-hearcom",
    "aria-label": "horizon go ix by hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Horizon Go IX by hear.com"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/",
    className: "c-md-a"
  }, "Horiozn Go IX"), " is a highly sought-after hearing aid due to its long-lasting rechargeable battery and ability to keep you connected throughout the day. Equipped with cutting-edge AX platform technology, this device effortlessly handles even the most challenging hearing scenarios.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-styletto-dark-granite.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-styletto",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-styletto",
    "aria-label": "signia styletto permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Signia Styletto"), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/",
    className: "c-md-a"
  }, "Signia"), " is one of the largest and most innovative hearing aid brands in the world. They continuously pave the way in the development of technology, focusing on enhancing human performance and removing limitations for those with hearing loss.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/phonak-marvel.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "phonak-marvel",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#phonak-marvel",
    "aria-label": "phonak marvel permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Phonak Marvel"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "As one of the leading hearing aid manufacturers, ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/phonak/marvel",
    className: "c-md-a"
  }, "Phonak Marvel"), " is once again changing the face of the industry. In its new Marvel receiver-in-canal (RIC) platform, Phonak leverages its proprietary AutoSense™ 3.0 operating system to deliver an unparalleled natural hearing experience.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-pure-x.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-pure",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-pure",
    "aria-label": "signia pure permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Signia Pure"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/",
    className: "c-md-a"
  }, "Signia range of Pure"), " hearing aids is incredible no matter your level of hearing loss. With these powerful Bluetooth hearing aids that sit discreetly behind your ear, you’ll be able to stream phone calls, TV and audio right into your ears.")), "\n", React.createElement(LandingPageCta, {
    copy: "Try Bluetooth hearing aids",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "frequently-asked-questions",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#frequently-asked-questions",
    "aria-label": "frequently asked questions permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Frequently asked questions"), "\n", React.createElement(QuestionAccordion, {
    question: "Is the app of the Bluetooth hearing aids compatible with my iPhone?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Yes. The hear.com's Horizon app can easily connect from Apple IOS to Bluetooth hearing aids.")), "\n", React.createElement(QuestionAccordion, {
    question: "Is the app of the Bluetooth hearing aids compatible with my Android phone?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Yes. The hear.com's Horizon app can easily connect from Android phones to Bluetooth hearing aids.")), "\n", React.createElement(QuestionAccordion, {
    question: "Do Bluetooth hearing aids have a longer battery life?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "The battery life of Bluetooth hearing aids varies depending on the model and usage. While streaming audio directly to the hearing aids may consume more power, advancements in technology have led to improved energy efficiency in many Bluetooth-enabled devices.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check your Hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
